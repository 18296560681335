module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-snipcart-advanced/gatsby-browser.js'),
      options: {"plugins":[],"publicApiKey":"Nzk2YjNmZTEtYTk0My00NGU3LWFiNGUtNzdkMGY3ZDIwMjM3NjM4MjE2NDkxMzE0MTcxNjU0","autopop":true},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
